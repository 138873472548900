<template>
  <div>
    <b-row>
      <iframe
        :src="`${urlApi}/g-drive`"
        style="height:50rem;width:99%;border:none;"
        title="G-Drive"
      ></iframe>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'googledrive',
  data() {
    return {
      urlApi: null,
    }
  },
  created() {
    this.urlApi = ApiService.urlApi()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Google Drive' }])
  },
}
</script>
